import { CceUsageMetricUsageStatus } from "./usage-management.model";

export const ENGLISH = {
    "usage-management": "Usage Management",
    "top-usages-with-commitment": "Top Usage Types with Commitment",
    "top-usages-button-help": "Show help topic that explains the top usage types with commitment chart",
    "top-last-30-days": "Last 30 days",
    "current-usage": "Current Usage",
    "usage-type": "Usage Type",
    service: "Service",
    unit: "Unit",
    usage: "Usage",
    commitment: "Commitment",
    status: "Status",
    overage: "Overage",
    seller: "Seller",
    "last-updated": "Last Updated",
    "usage-items-per-page": "Usage items per page",
    "no-matches": "No usage types matched your filters",
    "pagination-footer": "{0} - {1} of {2} items",
    ["usage-type-status." + CceUsageMetricUsageStatus.OVER_COMMITMENT]: "Over Commitment",
    ["usage-type-status." + CceUsageMetricUsageStatus.AT_COMMITMENT]: "At Commitment",
    ["usage-type-status." + CceUsageMetricUsageStatus.BELOW_COMMITMENT]: "Below Commitment",
    ["usage-type-status." + CceUsageMetricUsageStatus.ON_DEMAND]: "On-demand",
    ["usage-type-status." + CceUsageMetricUsageStatus.UNKNOWN]: "Unknown",
    "no-usage-info": "No Usage Information",
    "no-usage-info-grid": "No usage information for the current data grid.",
    "no-usage-info-grid-filtered": "No usage types were found for the current filter criteria.",
    "no-usage-info-available": "There is no usage information available for your organization currently. Please come back later.",
    "usage-may-be-available-in-service-console": "Usage data for your organization's services may be available in the service console.",
    "total-usage-types": "{0} usage types",
    "aws-data-in-aws-console": `Usage data for your services purchased from AWS is available in the <a href="{0}" target="_blank">AWS console <cds-icon shape="pop-out" style="margin-top: -3px;"></cds-icon></a>.`,
    "service-catalog": "Service Catalog",
    "no-org-subscriptions": "You do not yet have any subscriptions for this organization",
    multiple: "Multiple",
    "commitment-label": "{0} used of {1} hosts allocated",
    available: "Available",
    "within-commitments": "Usage Within Commitments",
    "threshold-label": "100% Commitment",
    "medium-datetime-minus-seconds-pattern": "d MMM y, h:mm a",
    "current-consumption": "Current Consumption",
    "stale-data": "The usage data on this page is for information only, it is not updated.",
};
